<template>
  <div>
		<page-header
			title="Your mental health"
		>
			<template v-slot:content>
				Now we're going to ask a few questions about how you're feeling and your mental health. This helps us to offer additional support if we think you need it.
			</template>
		</page-header>
		<page-container>
			<form
				autocomplete="off"
				@submit.prevent="nextStep"
			>
				<!-- Feeling Options -->
				<Radios
					:values="feelings"
					name="feeling"
					getter="getFeeling"
					updateMethod="updateFeeling"
					title="How are you feeling today?"
				/>
					
				<!-- Mental Health Options -->
				<Checkboxes
					:values="experiences"
					name="experiences"
					getter="getExperiences"
					updateMethod="updateExperiences"
					title="Now a question about your mental health. Have you ever experienced any of the following?"
					subtitle="Please select all that apply"
				/>

				<alert error v-if="error" type="danger">
					<small>{{ error }}</small>
				</alert>
				
				<form-submit :title="requiresECC ? 'Continue to pregnancy risk' : 'Finish'" />
			</form>
		</page-container>
  </div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Mixins
import createEntry from '@/mixins/create-entry'
import mixpanelTracking from '@/mixins/mixpanel'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import Checkboxes from '@/components/form/checkbox/group'
import Radios from '@/components/form/radio/group'
import formSubmit from '@/components/form/form-submit'

// General Components
import alert from '@/components/general/alert'

export default {
	mixins: [ createEntry, mixpanelTracking ],
	components: {
		pageHeader,
		pageContainer,
		formSubmit,
		Checkboxes,
		Radios,
		alert
	},
	data() {
		return {
			requiresECC: false,
			feelings: [
				{
					name: "Great",
					id: "feeling-great",
					image: "great"
				}, {
					name: "OK",
					id: "feeling-ok",
					image: "ok"
				}, {
					name: "Sad",
					id: "feeling-sad",
					image: "sad"
				}, {
					name: "Anxious",
					id: "feeling-anxious",
					image: "anxious"
				}, {
					name: "Worried",
					id: "feeling-worried",
					image: "worried"
				}
			],
			experiences: [
				{
					name: "Bullying",
					id: "experienced-bullying"
				}, {
					name: "Anxiety",
					id: "experienced-anxiety"
				}, {
					name: "Really low mood or depression",
					id: "experienced-depression"
				}, {
					name: "Thoughts of harming yourself",
					id: "experienced-thoughts-harm"
				}, {
					name: "Previous self harm/injury",
					id: "experienced-self-harm"
				}, {
					name: "Currently self harming/Injuring",
					id: "experienced-current-self-harm"
				}, {
					name: "Suicidal thoughts",
					id: "experienced-suicidal-thoughts"
				}, {
					name: "Suicidal thoughts with a plan for what you would do",
					id: "experienced-planned-suicidal-thoughts"
				}, {
					name: "No",
					id: "experienced-no"
				}, {
					name: "Prefer not to say",
					id: "experienced-prefer-not-to-say"
				}
			]
		}
	},
	computed: {
		...mapGetters([
			'getExperiences',
			'getFeeling',
			'getGenitals',
			'getHasHadSex',
			'getContraceptiveMethods',
			'getSuspectedContractionFailure'
		])
	},
	methods: {
		checkECC() {
			// Checks previous user data if ECC is required
			if (
				// Check required triggers for ECC
				this.getGenitals != 'Penis' &&
				this.getHasHadSex != 'No' &&
				this.getContraceptiveMethods.includes('No method being used') ||
				this.getGenitals != 'Penis' &&
				this.getHasHadSex != 'No' &&
				!this.getContraceptiveMethods.includes('No method being used') &&
				this.getSuspectedContractionFailure != 'No' 
			) {
				// Set requiresECC to true
				this.requiresECC = true
			}
		},
		async nextStep() {
			// Set stepE to complete
			await this.$store.commit('completeStepE', true)

			if ( this.requiresECC ) {

				// Proceed to ECC
				this.$store.commit('loading', false)
				
				await this.$router.push(
					this.$GLOBALS.PATH_PREGNANCY_RISK
				)

			} else {
				// Create entry
				await this.createEntry()
			}
		}
	},
	mounted() {
		this.checkECC()
	},
}
</script>
